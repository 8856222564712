/**
 * @generated SignedSource<<a520e406b6acbc9227a49a3d2bc59a90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrainSearchPageHeaderFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonBrainSearchFragment" | "BrainSearchMoreActionsButtonFragment">;
  readonly " $fragmentType": "BrainSearchPageHeaderFragment";
};
export type BrainSearchPageHeaderFragment$key = {
  readonly " $data"?: BrainSearchPageHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrainSearchPageHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrainSearchPageHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrainSearchMoreActionsButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonBrainSearchFragment"
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "f5b98c6188c1547ebb98407f0d02395c";

export default node;
