/**
 * @generated SignedSource<<d23ae6526e9429af32343d780eb35fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookmarkButtonBrainSearchFragment$data = {
  readonly hasViewerBookmarked: boolean;
  readonly id: string;
  readonly " $fragmentType": "BookmarkButtonBrainSearchFragment";
};
export type BookmarkButtonBrainSearchFragment$key = {
  readonly " $data"?: BookmarkButtonBrainSearchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonBrainSearchFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookmarkButtonBrainSearchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerBookmarked",
      "storageKey": null
    }
  ],
  "type": "BrainSearch",
  "abstractKey": null
};

(node as any).hash = "4639f3fb14c02dab16acd5ae521df802";

export default node;
