/**
 * @generated SignedSource<<bf156c8bf18db15355cb3a024dedefc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BookmarkButtonDeleteMutation$variables = {
  brainSearchId?: string | null;
  contentId?: string | null;
  contentUsageId?: string | null;
};
export type BookmarkButtonDeleteMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly brainSearch: {
        readonly hasViewerBookmarked: boolean;
        readonly id: string;
      } | null;
      readonly content: {
        readonly hasViewerBookmarked: boolean;
        readonly id: string;
      } | null;
      readonly contentUsage: {
        readonly hasViewerBookmarked: boolean;
        readonly id: string;
      } | null;
      readonly id: string;
    } | null;
  };
};
export type BookmarkButtonDeleteMutation = {
  response: BookmarkButtonDeleteMutation$data;
  variables: BookmarkButtonDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brainSearchId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "brainSearchId",
    "variableName": "brainSearchId"
  },
  {
    "kind": "Variable",
    "name": "contentId",
    "variableName": "contentId"
  },
  {
    "kind": "Variable",
    "name": "contentUsageId",
    "variableName": "contentUsageId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasViewerBookmarked",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Content",
  "kind": "LinkedField",
  "name": "content",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentUsage",
  "kind": "LinkedField",
  "name": "contentUsage",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BrainSearch",
  "kind": "LinkedField",
  "name": "brainSearch",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BookmarkButtonDeleteMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v3/*: any*/),
        "concreteType": "DeleteBookmarkResponse",
        "kind": "LinkedField",
        "name": "deleteBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bookmark",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BookmarkButtonDeleteMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v3/*: any*/),
        "concreteType": "DeleteBookmarkResponse",
        "kind": "LinkedField",
        "name": "deleteBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bookmark",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebfe4d709ebba707452a358afbefef2a",
    "id": null,
    "metadata": {},
    "name": "BookmarkButtonDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookmarkButtonDeleteMutation(\n  $contentId: ID\n  $contentUsageId: ID\n  $brainSearchId: ID\n) {\n  response: deleteBookmark(contentId: $contentId, contentUsageId: $contentUsageId, brainSearchId: $brainSearchId) {\n    node {\n      id\n      content {\n        id\n        hasViewerBookmarked\n      }\n      contentUsage {\n        id\n        hasViewerBookmarked\n      }\n      brainSearch {\n        id\n        hasViewerBookmarked\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e2b70f0196265b987dafc663a3614fb";

export default node;
