import { BookmarkButtonBrainSearchFragment$key } from "@/bookmarks/__generated__/BookmarkButtonBrainSearchFragment.graphql"
import { BookmarkButtonContentFragment$key } from "@/bookmarks/__generated__/BookmarkButtonContentFragment.graphql"
import { BookmarkButtonContentUsageFragment$key } from "@/bookmarks/__generated__/BookmarkButtonContentUsageFragment.graphql"
import {
  BookmarkButtonCreateMutation,
  BookmarkKind,
} from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { BookmarkButtonDeleteMutation } from "@/bookmarks/__generated__/BookmarkButtonDeleteMutation.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoIcon, DiscoIconButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { graphql, useFragment, useMutation } from "react-relay"

interface Props {
  className?: string
  contentUsageKey?: BookmarkButtonContentUsageFragment$key
  contentKey?: BookmarkButtonContentFragment$key
  brainSearchKey?: BookmarkButtonBrainSearchFragment$key
  showText?: boolean
  kind: BookmarkKind
  testid?: string
  children?: OverridableDiscoButtonChildren
  inHeader?: boolean
}

function BookmarkButton({
  contentUsageKey,
  contentKey,
  brainSearchKey,
  showText = false,
  inHeader = false,
  kind,
  testid = "BookmarkButton",
  children,
  className: customClassName,
}: Props) {
  const theme = useTheme()
  const classes = useStyles({ inHeader })

  const contentUsage = useFragment<BookmarkButtonContentUsageFragment$key>(
    graphql`
      fragment BookmarkButtonContentUsageFragment on ContentUsage {
        id
        hasViewerBookmarked
      }
    `,
    contentUsageKey || null
  )

  const content = useFragment<BookmarkButtonContentFragment$key>(
    graphql`
      fragment BookmarkButtonContentFragment on Content {
        id
        hasViewerBookmarked
      }
    `,
    contentKey || null
  )

  const brainSearch = useFragment<BookmarkButtonBrainSearchFragment$key>(
    graphql`
      fragment BookmarkButtonBrainSearchFragment on BrainSearch {
        id
        hasViewerBookmarked
      }
    `,
    brainSearchKey || null
  )

  const createMutation = Relay.useAsyncMutation<BookmarkButtonCreateMutation>(
    graphql`
      mutation BookmarkButtonCreateMutation($input: CreateBookmarkInput!) {
        response: createBookmark(input: $input) {
          node {
            id
            content {
              id
              hasViewerBookmarked
            }
            contentUsage {
              id
              hasViewerBookmarked
            }
            brainSearch {
              id
              hasViewerBookmarked
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const [deleteMutation] = useMutation<BookmarkButtonDeleteMutation>(
    graphql`
      mutation BookmarkButtonDeleteMutation(
        $contentId: ID
        $contentUsageId: ID
        $brainSearchId: ID
      ) {
        response: deleteBookmark(
          contentId: $contentId
          contentUsageId: $contentUsageId
          brainSearchId: $brainSearchId
        ) {
          node {
            id @deleteRecord
            content {
              id
              hasViewerBookmarked
            }
            contentUsage {
              id
              hasViewerBookmarked
            }
            brainSearch {
              id
              hasViewerBookmarked
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  if (!content && !contentUsage && !brainSearch) return null

  const bookmarkable = contentUsage || content || brainSearch
  const { hasViewerBookmarked } = bookmarkable!
  const tooltipContent = hasViewerBookmarked ? "Remove from Bookmarks" : "Bookmark"

  if (children) {
    return (
      <OverridableDiscoButton
        onClick={toggleBookmark}
        color={"transparent"}
        testid={`${testid}.bookmark-overridable-button`}
        stopPropagation
      >
        {children}
      </OverridableDiscoButton>
    )
  }

  return (
    <div className={classNames(classes.container, customClassName)}>
      <DiscoTooltip content={tooltipContent}>
        <DiscoButton
          testid={`${testid}.bookmark-button`}
          size={"small"}
          leftIcon={
            <DiscoIcon
              icon={"iconsax.custom-archive"}
              active={hasViewerBookmarked}
              width={16}
              height={16}
              className={
                hasViewerBookmarked ? classes.bookmarkIconFill : classes.bookmarkIcon
              }
            />
          }
          color={"grey"}
          onClick={(e) => {
            toggleBookmark()
            e.stopPropagation()
          }}
          className={classes.bookmarkButton}
          customButtonColor={{
            color:
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[200]
                : theme.palette.groovy.neutral[700],
            backgroundColor: theme.palette.background.paper,
            hover: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.groovy.neutral[200],
            },
          }}
        >
          {showText && "Bookmark"}
        </DiscoButton>
      </DiscoTooltip>
    </div>
  )

  async function toggleBookmark() {
    if (hasViewerBookmarked) {
      deleteMutation({
        variables: {
          contentId: content?.id,
          contentUsageId: contentUsage?.id,
          brainSearchId: brainSearch?.id,
        },
        optimisticUpdater: (store) => {
          if (content) {
            const contentConnection = store.get(content.id)
            if (contentConnection)
              contentConnection.setValue(false, "hasViewerBookmarked")
          }

          if (contentUsage) {
            const contentUsageConnection = store.get(contentUsage.id)
            if (contentUsageConnection)
              contentUsageConnection.setValue(false, "hasViewerBookmarked")
          }

          if (brainSearch) {
            const brainSearchConnection = store.get(brainSearch.id)
            if (brainSearchConnection)
              brainSearchConnection.setValue(false, "hasViewerBookmarked")
          }
        },
        onError(error) {
          displayErrorToast(error)
        },
        onCompleted({ response }) {
          if (response.errors) {
            displayErrorToast("Unable to remove bookmark")
            return
          }
          displayToast({
            message: "Bookmark Removed",
            testid: "BookmarkButton.removed-toast",
          })
        },
      })
    } else {
      const { response } = await createMutation({
        input: {
          contentId: content?.id,
          contentUsageId: contentUsage?.id,
          brainSearchId: brainSearch?.id,
          kind,
        },
      })
      if (response.errors) {
        displayErrorToast("Unable to add bookmark")
        return
      }
      displayToast({
        message: "Bookmark Added",
        testid: "BookmarkButton.added-toast",
      })
    }
  }
}

type StyleProps = {
  inHeader: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  bookmarkIcon: {
    "& > path": {
      color: `${
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[700]
      } !important`,
    },

    "&:is(svg)": {
      width: "16px",
      height: "16px",
    },
  },
  bookmarkIconFill: {
    "& > path": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  bookmarkButton: ({ inHeader }: StyleProps) => ({
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[700],
    gap: theme.spacing(0.25),
    padding: theme.spacing(1),
    height: "32px",
    "& span": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    "&:hover svg:not($bookmarkIconFill) path": {
      stroke: theme.palette.primary.main,
    },

    ...styleIf(inHeader, {
      padding: theme.spacing(1.5),
      height: "48px",
    }),
  }),
}))

export default Relay.withSkeleton({
  component: observer(BookmarkButton),
  skeleton: () => <DiscoIconButtonSkeleton />,
})
